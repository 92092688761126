/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaTwitterSquare, FaInstagramSquare, FaEnvelope } from "react-icons/fa"
import { IconContext } from "react-icons/lib"

const Bio = ({author}) => {
  const avatarUrl = author?.avatar?.url
  const authorNameNoSpace = author.name.replace(/ /g, '');

  return (
    <div className="bio">
      {avatarUrl && (
        <img
          alt={author?.name || ``}
          className="bio-avatar"
          src={avatarUrl}
        />
      )}
      {author?.userAdditionalInfo?.showbio && (
        <div>
          <p>
            Written by <strong>{author.name}</strong>
            {` `}
            {author?.userAdditionalInfo?.twitter && (
              <a href={`https://twitter.com/${author.userAdditionalInfo.twitter}`}>
                <IconContext.Provider value={{ title: "Twitter", color: "#2e353f", size: "1.9em", style: { paddingLeft: '0.5em' } }}>
                  <FaTwitterSquare/> 
                </IconContext.Provider> 
              </a>
            )}
            {author?.userAdditionalInfo?.instagram && (
              <a href={`https://instagram.com/${author.userAdditionalInfo.instagram}`}>
                <IconContext.Provider value={{ title: "Instagram", color: "#2e353f", size: "1.9em", style: { paddingLeft: '0.5em' } }}>
                  <FaInstagramSquare/>
                </IconContext.Provider> 
              </a>
            )}
            {author?.userAdditionalInfo?.publicemail && (
              <a href={`mailto://${author.userAdditionalInfo.publicemail}`}>
                <IconContext.Provider value={{ title: "Email", color: "#2e353f", size: "1.9em", style: { paddingLeft: '0.5em' } }}>
                  <FaEnvelope/>
                </IconContext.Provider> 
              </a>
            )}
          </p>
          <p>
            {author?.description || null}
            {` `}
          </p>
          <a href={`/authors/${authorNameNoSpace}`}>Read More Articles</a>
        </div>
      )}
    </div>
  )
}

export default Bio
